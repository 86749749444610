import React, { Component } from 'react'
import AssignmentIcon from '@material-ui/icons/Assignment'
import { IconButton, withStyles, withTheme } from '@material-ui/core'
import { FormattedDate, FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { getStyles, injectToolbarData, SpringDataTable, Title } from 'isotope-client'
import { download } from 'isotope-client/components/download/IsotopeLink'
import {
	DownloadIcon,
	EmptyResult,
	injectProfil,
	FilArianeProfil,
	PjPopup,
	getInscriptionPiecesJustificatives
} from '@oceane/ui'

const style = () => getStyles({
	download: {
		padding: 0,
		'&:hover': {
			backgroundColor: 'transparent'
		}
	}
})

class Examens extends Component {
	render() {
		const {
			etatCivil: {
				id,
				numeroOceane,
				prenomPrincipal,
				nomNaissance
			},
			getInscriptionPiecesJustificatives,
			classes
		} = this.props

		if (id) {
			return (
				<React.Fragment>
					<Title value={<FormattedMessage id="candidats.profil.examens.pratiques"/>}/>
					<SpringDataTable
						apiUrl={`/candidats/${id}/examens`}
						nom="examensCandidat"
						headers={[
							{
								key: 'examenType.libelleCourt',
								name: <FormattedMessage id='headers.examen'/>,
								render: row => row.typeExamen,
								sortable: true
							},
							{
								key: 'i.numeroInscription',
								name: <FormattedMessage id='headers.numeroInscription'/>,
								render: row => row.numeroInscription,
								sortable: true
							},
							{
								key: 'etat',
								name: <FormattedMessage id='headers.etat'/>,
								render: row => <FormattedMessage id={`etatExamenCandidat.${row.etat}`}/>,
								sortable: true
							},
							{
								key: 'dateEtat',
								name: <FormattedMessage id='headers.date'/>,
								render: row => <FormattedDate value={row.dateEtat}/>,
								sortable: true
							},
							{
								key: 'piecesJustificatives',
								name: <FormattedMessage id='headers.piecesJustificatives'/>,
								render: row => <IconButton
									onClick={() => getInscriptionPiecesJustificatives(row, false)}
								>
									<AssignmentIcon/>
								</IconButton>
							},
							{
								key: 'ficheRecapitulative',
								name: <FormattedMessage id='headers.ficheRecapitulativeAffectation'/>,
								render: row => {
									if (row.etatInscription >= 3 && row.diffusionFRI) { // Si l'état de l'inscription est à "affectée" ou au delà
										return <IconButton
											disableRipple
											className={classes.download}
											onClick={() => download(`/files/inscriptions/${row.idInscription}/ficheRecapitulative`)}
										>
											<DownloadIcon/>
										</IconButton>
									}
									return null
								}
							}
						]}
						noResultFragment={<EmptyResult/>}
					/>
					<PjPopup
						etatCivil={{
							numeroOceane,
							prenomPrincipal,
							nomNaissance
						}}
					/>

				</React.Fragment>
			)
		}
		return null
	}
}

Examens.propTypes = {
	etatCivil: PropTypes.object,
	getPiecesJustificatives: PropTypes.func
}

const actions = {
	getInscriptionPiecesJustificatives
}

export default compose(
	injectProfil,
	withTheme(),
	withStyles(style),
	connect(undefined, actions),
	withTheme(),
	injectToolbarData(({ etatCivil: { id, numeroOceane, nomNaissance, prenomPrincipal }}) => FilArianeProfil(id, numeroOceane, nomNaissance, prenomPrincipal)),
)(Examens)
